<template>
  <form-modal v-bind="bindChain.form" v-on="onChain.form">
    <v-form :ref="formRef">
      <v-text-field
        v-model="form.nombre"
        name="empresa"
        label="Nombre"
        :rules="[(v) => !!v || 'El campo nombre es requerido.']"
        placeholder="Escribe el nombre de la empresa..."
      >
      </v-text-field>

      <v-text-field
        v-model="form.descripcion"
        name="description"
        label="Descripción"
        :rules="[(v) => !!v || 'El campo descripción es requerido.']"
        placeholder="Escribe una descripcion para la empresa..."
      >
      </v-text-field>
      <v-text-field
        v-model="form.codigo"
        name="tipoDoc"
        label="Código"
        :rules="[(v) => !!v || 'El campo código es requerido.']"
        placeholder="Escribe el código..."
      >
      </v-text-field>
    </v-form>
  </form-modal>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";

export default {
  name: "EmpresasForm",
  mixins: [FormMixin],
  data() {
    return {
      defaultTitle: "Formulario de empresas",
      form: {
        nombre: "",
        descripcion: "",
        codigo: "",
      },
    };
  },
  methods: {
    setter() {
      this.form.nombre = this.formData.empresa;
      this.form.descripcion = this.formData.empresa_descripcion;
      this.form.codigo = this.formData.codigo;
    },
  },
};
</script>
