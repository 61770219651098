<template>
  <v-container>
    <v-card>
      <v-data-table :search="search" v-bind="bind.table">
        <template #top>
          <table-top v-bind="bindChain.tableTop" v-on="onChain.tableTop" />
        </template>

        <template #item.actions="{ item }">
          <table-actions
            v-bind="bindChain.tableActions"
            v-on="onChain.tableActions(item)"
          />
        </template>
      </v-data-table>
    </v-card>
    <Form v-model="dialog" v-bind="bind.form" v-on="on.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>
<script>
import PageMixin from "@/Mixins/SetPageMixin.js";
import Form from "@/components/Forms/integrations/empresas.vue";
import { mapActions } from "vuex";

export default {
  name: "EmpresasConfigPage",
  mixins: [PageMixin],
  components: {
    Form,
  },
  data: () => ({
    headers: [
      { text: "Nombre", value: "empresa", align: "left" },
      { text: "Código", value: "codigo", align: "left" },
      { text: "Descripción", value: "empresa_descripcion", align: "left" },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    empresas: [],
    itemId: "id",
  }),

  computed: {
    items() {
      return this.empresas;
    },
  },

  methods: {
    ...mapActions("Integrator", [
      "getCompanies",
      "CrearEmpresa",
      "deleteEmpresa",
      "UpdateEmpresa",
    ]),

    setup() {
      this.loading = true;
      this.getCompanies()
        .catch((error) => {
          this.snackbar(error.info);
        })
        .then((response) => {
          this.empresas = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveMethod() {
      return this.CrearEmpresa;
    },
    deleteMethod() {
      return this.deleteEmpresa;
    },
    updateMethod() {
      return this.UpdateEmpresa;
    },
  },
};
</script>
